import { ActionContext } from 'vuex';

import { AddAddressJob } from '@/js/interfaces/address-job';

import State from '../state';

interface JobState {
    currentStep: number;
    addressJob: AddAddressJob;
}
const emptyAddressJob: AddAddressJob = {
  job: undefined,
  workingTime: undefined,
  remote: undefined,
  town: undefined,
  contracts: [],
  experiences: [],
  personalQualities: [],
  professionalKnowledge: [],
  professionalSoftSkills: [],
  active: true,
  urgent: false,
  similarJobs: [],
  jobDescription: undefined,
  monthlySalary: undefined,
  languages: [],
  licences: [],
  locomotions: [],
  caces: [],

};

const defaultState: JobState = {
  currentStep: 1,
  addressJob: emptyAddressJob,
};

type Context = ActionContext<JobState, State>;

const mutations = {
  setCurrentStep(state: JobState, currentStep: number) {
    state.currentStep = currentStep;
  },
  setAddressJob(state: JobState, addressJob: AddAddressJob): void {
    state.addressJob = addressJob;
  },
  resetAddressJob(state: JobState) {
    state.addressJob = emptyAddressJob;
  },
  updateAddressJobTown(state: JobState, town) {
    if (state.addressJob) {
      state.addressJob.town = town;
    }
  },
  stepBack(state: JobState) {
    if (state.currentStep > 1) state.currentStep -= 1;
  },
};
const getters = {};
const actions = {};
const job = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default job;
