<template>
  <footer class="footer">
    <div class="container">
      <div class="text-center">
        <img src="../../images/salvatore-logo-white.svg" alt="salvatore.work" class="">
      </div>

      <div class="footer-copyright">
        <span class="footer-copyright-link">© {{ currentYear }} salvatore.work — Tous droits réservés</span>
        <a class="footer-copyright-link" href="/conditions-generales">Conditions générales</a>
        <a class="footer-copyright-link" href="/politique-de-confidentialite">Politique de confidentialité</a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RegisterFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
});
</script>
