<template>
  <div class="application">
    <div class="application-user-section">
      <div class="candidate-avatar-wrapper">
        <img
          v-if="application.candidate.avatar"
          class="candidate-avatar rounded"
          :src="application.candidate.avatar"
          :alt="`${application.user.firstName} ${application.user.lastName}`"
        >

        <div v-else class="empty-avatar rounded d-flex justify-content-center align-items-center">
          <span class="text-blue empty-avatar-text text-s">{{ candidateNameFirstLetters }}</span>
        </div>
      </div>

      <div :class="[isOnResumesPage ? 'd-flex align-items-center flex-wrap' : '']">
        <div class="candidate-name">
          {{ application.user.firstName }} {{ application.user.lastName }}
        </div>
        <div class="candidate-age-city text-s d-flex align-items-center flex-wrap">
          <span class="candidate-age" :class="[isOnResumesPage ? 'before' : '']">{{ application.candidate.age }} ans</span>
          <span class="candidate-city">
            {{
              application.candidate.address?.context?.[2]?.text ?? 'Localisation non renseignée'
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="application-job-section">
      <span v-if="application.job" class="text-primary application-job text-center d-inline-block" :title="application.job.name">
        {{ application.job.name }}
      </span>

      <template v-if="shouldDisplayAddress">
        <template v-if="application.businessUnit">
          <span class="pole">{{ application.businessUnit.name }}</span>
        </template>
      </template>
    </div>

    <button type="button" class="application-favorite" @click.prevent="toggleFavorite">
      <v-sprite v-if="!application.favorite" icon="heart" color="#9DA9C3" />
      <v-sprite v-if="application.favorite" icon="heart" color="#ff0e8d" />
    </button>

    <div class="application-actions">
      <router-link
        v-if="subscriptionIsActive || true"
        class="profile-link text-blue fw-700"
        :to="{
          name: routeNames.candidateResumeDetail,
          params: { uuid: application.user.uuid },
          query: { applicationUuid: application.uuid },
        }"
      >
        <span class="d-none d-md-block">Voir le profil</span>
        <v-sprite class="d-block d-md-none" icon="right-arrow" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapGetters, mapState } from 'vuex';

import Application from '../interfaces/application';
import routesNames, { RoutesNames } from '../router/route-names';
import { getUserNameFirstLetters } from '../services/utils';

export default defineComponent({
  name: 'ApplicationsListItem',
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localApplication: this.application as Application,
    };
  },
  computed: {
    ...mapGetters('recruiter', [
      'subscriptionIsActive',
    ]),
    ...mapState('recruiter', [
      'currentAddress',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
    shouldDisplayAddress(): boolean {
      return this.isOnResumesPage || this.currentAddress.childrenLength;
    },
    isOnResumesPage(): boolean {
      return this.$route.name === routesNames.resumes;
    },
    candidateNameFirstLetters(): string {
      return getUserNameFirstLetters(this.application.user.firstName, this.application.user.lastName);
    },
  },
  methods: {
    async toggleFavorite(): Promise<void> {
      this.localApplication.favorite = !this.localApplication.favorite;

      try {
        await window.ky.patch(`/api/applications/${this.application.uuid}/`, {
          json: {
            favorite: this.localApplication.favorite,
          },
        }).json<Application>();
      } catch {
        this.toast.error((this.$t('components.address_card.update.error') as string));
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.application {
  align-items: center;
  border-bottom: $border-width solid $border-color;
  color: $gray-500;
  display: grid;
  gap: $spacer;
  grid-template-areas:
    'user actions'
    'job actions';
  grid-template-columns: 1fr auto;
  padding: 15px 0;

  @include mq($from: tablet) {
    grid-template-areas: 'user job favorite actions';
    grid-template-columns: 4fr 6fr 24px 2fr;
  }
}

.application-user-section {
  align-items: center;
  display: flex;
  grid-area: user;
}

.application-job-section {
  display: flex;
  flex-direction: column;
  gap: 16px 40px;
  grid-area: job;

  @include mq($from: mobile) {
    align-items: center;
    flex-flow: row wrap;
  }

  @include mq($from: wide-xl) {
    justify-content: center;
  }

  .pole {
    color: #8c8c8c;
    font-size: 14px;
  }
}

.application-address {
  grid-area: address;
}

.application-favorite {
  background: none;
  border: 0;
  padding: 0;
}

.application-actions {
  grid-area: actions;
  margin-left: auto;
}

.candidate-avatar-wrapper {
  margin-right: 10px;
}

.candidate-avatar,
.empty-avatar {
  height: 40px;
  width: 40px;
}

.empty-avatar {
  background-color: $light;

  .empty-avatar-text {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
  }
}

.candidate-age-city,
.application-address {
  color: #8c8c8c;

  .candidate-age::after {
    content: '•';
    margin: 0 5px;
  }

  .candidate-age.before::before {
    content: '•';
    margin: 0 5px;
  }
}

.application-job {
  background-color: #f5f4fb;
  border-radius: 10px;
  font-size: 12px;
  max-width: 120px;
  overflow: hidden;
  padding: 8px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-link {
  font-size: 14px;
  text-align: right;
}
</style>
