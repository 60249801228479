<template>
  <div class="breadcrumb">
    <div class="progress">
      <div
        v-if="isHeadHunter"
        class="progress-bar"
        role="progressbar"
        :style="{ width: stepWidth }"
        :aria-valuenow="(currentStep / 3) * 100"
        aria-valuemin="0"
        aria-valuemax="100"
      />
      <div
        v-else
        class="progress-bar"
        role="progressbar"
        :style="{ width: stepWidth }"
        :aria-valuenow="(currentStep / 2) * 100"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
    <span v-if="isHeadHunter" class="current-step">{{ $t('components.register_breadcrumb.step') }} {{ currentStep }}/3</span>
    <span v-else class="current-step">{{ $t('components.register_breadcrumb.step') }} {{ currentStep }}/2</span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

export default defineComponent({
  name: 'AddAddressJobBreadCrumb',
  data() {
    return {};
  },
  computed: {
    ...mapState('job', [
      'currentStep',
    ]),
    ...mapGetters('recruiter', [
      'isHeadHunter',
    ]),
    stepWidth() {
      if (this.isHeadHunter) {
        return `${(this.currentStep / 3) * 100}%`;
      }
      return `${(this.currentStep / 2) * 100}%`;
    },
  },

});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.breadcrumb {
  align-items: center;
  gap: 10px;
  width: 100%;

  .progress {
    flex-grow: 1;
  }

  .current-step {
    color: $steel;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
