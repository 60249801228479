<template>
  <div class="resumes-shop-list">
    <div class="resumes-header-filter">
      <v-resumes-job-filter
        v-model:selected-job="selectedJobFilter"
      />
    </div>
    <div class="candidates-content">
      <div v-if="loading" class="d-flex justify-content-center">
        <div class="spinner-border text-primary mt-2 mb-3">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-else-if="localFavoriteCandidatesList.length > 0">
        <p class="candidates-matching-title">
          {{ $t('components.resume_shop_list.favorite_candidates_count', { count: localFavoriteCandidatesList.length }) }}
        </p>
        <div class="candidates-grid">
          <v-candidate-card
            v-for="candidate in localFavoriteCandidatesList"
            :key="candidate"
            :candidate="candidate"
            @refresh-resumes="getMatchingFavoriteCandidateList()"
          />
        </div>
      </div>
      <div v-else>
        <div class="missing-favorites">
          <h4 class="title">Ajoutez en favoris les candidats qui vous intéressent</h4>
          <img class="img" src="@/images/svg/missing-favorites.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DebouncedFunc, throttle } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapMutations } from 'vuex';

import { CandidateShopItem } from '../interfaces/candidate-shop-item';
import Job from '../interfaces/job';
import PaginatedResult from '../interfaces/pagination';

export default defineComponent({
  name: 'ResumeShopFavorites',
  emits: ['refresh-resumes'],
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      localFavoriteCandidatesList: [] as CandidateShopItem[],
      loading: true,
      page: 1,
      count: 0,
      totalPages: 2,
      pageSize: 5,
      previousPageNumber: null as (null | number),
      nextPageNumber: null as (null | number),
      lookupAddressHandler: throttle(this.getMatchingFavoriteCandidateList, 1000) as DebouncedFunc<()=> void>,
      selectedJobFilter: null as Job | null,

    };
  },
  computed: {
    queryParams(): URLSearchParams {
      const queryParameters = new URLSearchParams();

      if (this.selectedJobFilter) {
        const ids = [this.selectedJobFilter.id.toString()];

        if (this.selectedJobFilter.similarJobs && this.selectedJobFilter.similarJobs.length > 0) {
          for (const job of this.selectedJobFilter.similarJobs) {
            ids.push(job.id.toString());
          }
        }

        queryParameters.append('candidate__searched_jobs__job__in', ids.join(','));
      }

      return queryParameters;
    },
  },
  watch: {
    async queryParams(newValue: string, oldValue: string): Promise<void> {
      if (newValue !== oldValue) {
        this.setPage(this.page);
      }
    },
  },
  async mounted(): Promise<void> {
    await this.getMatchingFavoriteCandidateList();
    this.setResumesFilterCount(this.localFavoriteCandidatesList.length);
  },
  methods: {
    ...mapMutations('resumeShop', [
      'setResumesFilterCount',
    ]),
    async getMatchingFavoriteCandidateList(): Promise<void> {
      let url = '/api/favorite-candidate/';
      const pageParameters: {page: number;} = { page: this.page };
      const queryParameters = new URLSearchParams(pageParameters as Record<number, string>);

      url += `?${queryParameters.toString()}`;
      if (this.queryParams) {
        url += `&${this.queryParams}`;
      }

      try {
        const {
          count,
          totalPages,
          pageSize,
          previousPageNumber,
          nextPageNumber,
          results,
        } = await window.ky.get(url).json<PaginatedResult<CandidateShopItem>>() as PaginatedResult<CandidateShopItem>;
        if (results) {
          this.count = count;
          this.totalPages = totalPages;
          this.pageSize = pageSize;
          this.previousPageNumber = previousPageNumber;
          this.nextPageNumber = nextPageNumber;
          this.localFavoriteCandidatesList = results.sort((a, b) => b.matchingPercentage - a.matchingPercentage);
        }
      } catch {
        this.toast.error((this.$t('views.candidates.error') as string));
      } finally {
        this.loading = false;
      }
    },
    setPage(page: number): void {
      this.page = page;
      this.lookupAddressHandler();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.missing-favorites {
  color: $body-color;
  margin-inline: auto;
  max-width: 100%;
  padding: 20px;
  width: 400px;

  @include mq($from: mobile) {
    padding-inline: 0;
  }

  .title {
    font-size: 20px;
    margin-bottom: 32px;
    text-align: center;
  }
}
</style>
