<template>
  <div v-if="jobFilters.length > 0" class="resumes-filter">
    <div class="resume-filter jobs dropdown standard-select">
      <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleJobFilters">
        {{ $t('components.resumes_filters.jobs') }}
      </button>

      <ul class="dropdown-menu" :class="{ show: showJobFilters }">
        <li>
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: selectedJobs === null }"
            @click.prevent="selectJob(null)">
            <v-checkbox-indicator :checked="selectedJobs === null" />
            {{ $t('components.resumes_filters.all') }} ({{ totalCount }})
          </a>
        </li>
        <li
          v-for="job in jobFilters"
          :key="job.id">
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: selectedJobsIds && selectedJobsIds.indexOf(job.id) > -1 }"
            @click.prevent="selectJob(job)">
            <v-checkbox-indicator :checked="selectedJobsIds && selectedJobsIds.indexOf(job.id) > -1" />
            {{ job.name }} ({{ job.count }})
          </a>
        </li>
      </ul>
    </div>

    <div class="resume-filter experiences dropdown standard-select">
      <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleExperienceFilters">
        {{ $t('components.resumes_filters.experience') }}
      </button>

      <ul class="dropdown-menu" :class="{ show: showExperienceFilters }">
        <li>
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: selectedExperiences === null }"
            @click.prevent="selectExperience(null)">
            <v-checkbox-indicator :checked="selectedExperiences === null" />
            {{ $t('components.resumes_filters.all') }}
          </a>
        </li>
        <li
          v-for="experience in experienceFilters"
          :key="experience.id">
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: selectedExperiencesIds && selectedExperiencesIds.indexOf(experience.id) > -1 }"
            @click.prevent="selectExperience(experience)">
            <v-checkbox-indicator :checked="selectedExperiencesIds && selectedExperiencesIds.indexOf(experience.id) > -1" />
            {{ experience.name }}
          </a>
        </li>
      </ul>
    </div>

    <div class="resume-filter business-units dropdown standard-select">
      <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleBusinessUnitFilters">
        {{ $t('components.resumes_filters.business_units') }}
      </button>

      <ul class="dropdown-menu" :class="{ show: showBusinessUnitFilters }">
        <li>
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: selectedBusinessUnitIds.length === 0 }"
            @click.prevent="selectBusinessUnit(null)">
            <v-checkbox-indicator :checked="selectedBusinessUnitIds.length === 0" />
            {{ $t('components.resumes_filters.all') }}
          </a>
        </li>
        <li
          v-for="unit in businessUnitFilters"
          :key="unit.id">
          <a
            href="#"
            class="dropdown-item"
            :class="{ active: selectedBusinessUnitIds.includes(unit.id) }"
            @click.prevent="selectBusinessUnit(unit)">
            <v-checkbox-indicator :checked="selectedBusinessUnitIds.includes(unit.id)" />
            {{ unit.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapState } from 'vuex';

import type { BusinessUnit } from '../interfaces/business-unit';
import Experience from '../interfaces/experience';
import Job from '../interfaces/job';
import routesNames, { RoutesNames } from '../router/route-names';

interface JobFilter {
  id: number;
  name: string;
  count: number;
}

export default defineComponent({
  name: 'ResumesFilters',
  props: {
    selectedJobs: {
      type: Object as PropType<Job[] | null>,
      default: null,
    },
    selectedExperiences: {
      type: Object as PropType<Experience[] | null>,
      default: null,
    },
    selectedBusinessUnits: {
      type: Array as PropType<BusinessUnit[] | null>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:selectedJobs', 'update:selectedExperiences', 'update:selectedBusinessUnits'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      jobFilters: [] as JobFilter[],
      experienceFilters: [] as Experience[],
      businessUnitFilters: [] as { id: number; name: string; }[],
      selectedBusinessUnitIds: [] as number[],
      showJobFilters: false,
      showExperienceFilters: false,
      showBusinessUnitFilters: false,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'currentAddress',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
    selectedJobsIds(): number[] {
      let ids: number[] = [];

      if (this.selectedJobs && this.selectedJobs.length > 0) {
        ids = this.selectedJobs.map((job) => job.id);
      }

      return ids;
    },
    selectedExperiencesIds(): number[] {
      let ids: number[] = [];

      if (this.selectedExperiences && this.selectedExperiences.length > 0) {
        ids = this.selectedExperiences.map((job) => job.id);
      }

      return ids;
    },
  },
  watch: {
    async currentAddress(): Promise<void> {
      await Promise.all([
        this.getJobFilters(),
        this.getExperienceFilters(),
        this.getBusinessUnitFilters(),
      ]);
    },
  },
  async mounted(): Promise<void> {
    await Promise.all([
      this.getJobFilters(),
      this.getExperienceFilters(),
      this.getBusinessUnitFilters(),
    ]);
  },
  methods: {
    selectBusinessUnit(unit: { id: number; name: string; } | null): void {
      if (unit) {
        const index = this.selectedBusinessUnitIds.indexOf(unit.id);
        if (index === -1) {
          this.selectedBusinessUnitIds.push(unit.id);
        } else {
          this.selectedBusinessUnitIds.splice(index, 1);
        }
      } else {
        this.selectedBusinessUnitIds = [];
      }

      this.$emit('update:selectedBusinessUnits', this.selectedBusinessUnitIds);
    },
    async getBusinessUnitFilters(): Promise<void> {
      try {
        this.businessUnitFilters = await window.ky
          .get(`/api/addresses/${this.currentAddress.uuid}/business-units/`)
          .json();
      } catch {
        this.toast.error(this.$t('views.resume_filters.error') as string);
      }
    },
    async getJobFilters(): Promise<void> {
      const queryParameters = new URLSearchParams();

      try {
        this.jobFilters = await window.ky.get(`/api/addresses/${this.currentAddress.uuid}/filters/?${queryParameters.toString()}`).json();
      } catch {
        this.toast.error((this.$t('views.resume_filters.error') as string));
      }
    },

    async getExperienceFilters(): Promise<void> {
      try {
        this.experienceFilters = await window.ky.get('/api/experiences/').json();
      } catch {
        this.toast.error((this.$t('views.resume_filters.error') as string));
      }
    },

    selectJob(job: Job | null): void {
      let newJobs: Job[] | null = [];

      if (job) {
        newJobs = this.selectedJobs ?? [];

        const jobSelected = this.selectedJobsIds.indexOf(job.id);

        if (jobSelected === -1) {
          newJobs.push(job);
        } else {
          newJobs = newJobs.filter((newJob) => newJob.id !== job.id);
        }
      }

      if (newJobs.length === 0) {
        newJobs = null;
      }

      this.$emit('update:selectedJobs', newJobs);
    },

    selectExperience(experience: Experience|null): void {
      let newExperiences: Experience[]|null = [];

      if (experience) {
        newExperiences = this.selectedExperiences ?? [];

        const experienceSelected = this.selectedExperiencesIds.indexOf(experience.id);

        if (experienceSelected === -1) {
          newExperiences.push(experience);
        } else {
          newExperiences = newExperiences.filter((newExperience) => newExperience.id !== experience.id);
        }
      }

      if (newExperiences.length === 0) {
        newExperiences = null;
      }

      this.$emit('update:selectedExperiences', newExperiences);
    },

    toggleJobFilters(): void {
      this.showJobFilters = !this.showJobFilters;
      this.showExperienceFilters = false;
      this.showBusinessUnitFilters = false;
    },

    toggleExperienceFilters(): void {
      this.showExperienceFilters = !this.showExperienceFilters;
      this.showJobFilters = false;
      this.showBusinessUnitFilters = false;
    },

    toggleBusinessUnitFilters(): void {
      this.showBusinessUnitFilters = !this.showBusinessUnitFilters;
      this.showJobFilters = false;
      this.showExperienceFilters = false;
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.resumes-filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: $grid-gutter-width * .5;
  margin: 15px 0;
}

.resumes-filter-label {
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}

.resumes-header-filter {
  .dropdown-item {
    display: inline-flex;
    font-size: 14px;
    gap: 5px;
  }

  .form-check-input {
    cursor: pointer;
    margin-top: 2px;
  }
}

.standard-select {
  min-width: 280px;

  &::after {
    background-color: $purple;
  }

  .dropdown-toggle {
    color: $purple;
    text-align: left;

    &::after {
      content: none;
    }
  }
}
</style>
