document.addEventListener('DOMContentLoaded', () => {
  const { userAgent } = navigator;
  let appUrl = '#';

  const androidAppUrl = process.env.PLAY_STORE_LINK!;
  const iosAppUrl = process.env.APP_STORE_LINK!;

  if (/android/i.test(userAgent)) {
    appUrl = androidAppUrl;
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    appUrl = iosAppUrl;
  }

  const downloadLink = document.querySelector('#download-link');
  if (downloadLink) {
    downloadLink.setAttribute('href', appUrl);
  }
});
