document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('header.candidate-header') as HTMLElement;
  const mobileAlert = document.querySelector('.mobile-alert') as HTMLElement;

  if (header && mobileAlert) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 30) {
        header.style.paddingTop = '115px';
        mobileAlert.style.position = 'fixed';
      } else {
        header.style.paddingTop = '30px';
        mobileAlert.style.position = 'static';
      }
    });
  }
});
