class JobSearch {
  suggestedJobs: Array<{ name: string; }>;

  jobInput: HTMLInputElement;

  selectedJobInput: HTMLInputElement;

  form: HTMLFormElement;

  isValidSelection: boolean;

  errorContainer: HTMLElement | null;

  constructor() {
    this.suggestedJobs = [];
    this.jobInput = document.querySelector('#job-search-form-query') as HTMLInputElement;
    this.selectedJobInput = document.querySelector('#selected-job') as HTMLInputElement;
    this.form = document.querySelector('#job-search-form') as HTMLFormElement;
    this.isValidSelection = false;

    // Création dynamique de .error-dropdown si elle n'existe pas
    this.errorContainer = document.querySelector('.error-dropdown');
    if (!this.errorContainer) {
      this.errorContainer = document.createElement('div');
      this.errorContainer.classList.add('error-dropdown');
      this.errorContainer.style.display = 'none';
      const resultsContainer = document.querySelector('#job-search-form-results');
      if (resultsContainer) {
        resultsContainer.parentNode!.insertBefore(this.errorContainer, resultsContainer);
      }
    }

    // Effacer l'input au chargement
    this.clearSearchField();

    // Effacer aussi l'input si l'utilisateur revient en arrière
    window.addEventListener('popstate', () => this.clearSearchField());

    // 🔄 Supprimer la valeur du champ même si la page est restaurée depuis le cache
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        this.clearSearchField();
      }
    });
  }

  clearSearchField() {
    this.jobInput.value = '';
    this.selectedJobInput.value = '';
    this.isValidSelection = false;
  }

  buildDropdown() {
    const jobs = this.suggestedJobs;
    const query = this.jobInput.value.trim().toLowerCase();

    const dropdown = document.createElement('div');
    dropdown.classList.add('dropdown');
    dropdown.innerHTML = `
      <ul class="" aria-labelledby="dropdownMenuButton">
        ${jobs
          .map((job) => {
            const regex = new RegExp(`(${query})`, 'gi');
            const highlightedName = job.name.replace(regex, '<em>$1</em>');

            return `
              <li>
                <a class="dropdown-item job-option" href="/app/register/1/?job=${job.name}" data-name="${job.name}">
                  ${highlightedName}
                </a>
              </li>
            `;
          })
          .join('')}
      </ul>
    `;

    for (const item of dropdown.querySelectorAll('.job-option')) {
      item.addEventListener('click', (event) => {
        event.preventDefault();
        let target = event.target as HTMLElement;

        if (target.tagName === 'EM') {
          target = target.closest('.job-option') as HTMLElement;
        }

        const jobName = target.dataset.name;

        if (jobName) {
          this.jobInput.value = jobName;
          if (this.selectedJobInput) {
            this.selectedJobInput.value = jobName;
          }
          this.isValidSelection = true;
          this.clearError();
          this.emptyDropdown();

          history.pushState({}, '', window.location.href);

          window.location.href = `/app/register/1/?job=${jobName}`;
        }
      });
    }

    return dropdown;
  }

  emptyDropdown() {
    const results = document.querySelector('#job-search-form-results');
    if (results) {
      results.innerHTML = '';
      this.suggestedJobs = [];
    }
  }

  handleJobSearch() {
    if (this.jobInput) {
      this.jobInput.addEventListener('keyup', (event) => {
        event.preventDefault();
        const query = this.jobInput.value.trim();
        this.isValidSelection = false;

        if (query.length < 3) {
          this.emptyDropdown();
          return;
        }

        const url = `/api/jobs/search/?term=${query}`;

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.hits.length === 0) {
              this.emptyDropdown();
              return;
            }

            this.suggestedJobs = data.hits.slice(0, 5);

            const dropdown = this.buildDropdown();
            const results = document.querySelector('#job-search-form-results');

            if (results) {
              results.innerHTML = '';
              results.append(dropdown);
            }
          });
      });
    }
  }

  preventInvalidSubmission() {
    this.form.addEventListener('submit', (event) => {
      const inputValue = this.jobInput.value.trim();
      const matchedJob = this.suggestedJobs.find(job => job.name.toLowerCase() === inputValue.toLowerCase());

      if (!this.isValidSelection && !matchedJob) {
        event.preventDefault();
        this.displayError('Veuillez sélectionner un métier dans la liste des suggestions avant de valider.');
      } else if (matchedJob) {
        history.pushState({}, '', window.location.href);

        window.location.href = `/app/register/1/?job=${matchedJob.name}`;
      }
    });
  }

  displayError(message: string) {
    if (this.errorContainer) {
      this.errorContainer.innerHTML = `<p class="error-message">${message}</p>`;
      this.errorContainer.style.display = 'block';
    }
  }

  clearError() {
    if (this.errorContainer) {
      this.errorContainer.innerHTML = '';
      this.errorContainer.style.display = 'none';
    }
  }

  scrollToForm() {
    const jobForm = document.querySelector('#job-search-form') as HTMLElement;
    if(jobForm) {
      this.jobInput.addEventListener('focus', () => {
        window.scrollTo(0, jobForm.offsetTop - 120);
      });
    }
  }
}

window.addEventListener('load', () => {
  if (document.querySelector('#job-search-form-query')) {
    const jobSearch = new JobSearch();
    jobSearch.handleJobSearch();
    jobSearch.preventInvalidSubmission();
    jobSearch.scrollToForm();
  }
});
