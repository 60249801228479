<template>
  <div class="affiliate-mobile-header d-xl-none">
    <router-link :to="{ name: affiliateRoutesNames.dashboard }">
      <img class="mobile-logo" src="@/images/sw-logo.svg" alt="" />
    </router-link>

    <button
      type="button"
      aria-controls="offcanvas"
      class="d-xl-none btn btn-offcanvas"
      @click="toggleMenu"
    >
      <v-sprite icon="burger" />
    </button>
    <div id="offcanvas" ref="offcanvas" class="offcanvas offcanvas-start" tabindex="-1">
      <div class="offcanvas-header">
        <router-link :to="{ name: affiliateRoutesNames.dashboard }" @click="closeMenu">
          <img class="mobile-logo" src="@/images/sw-logo.svg" alt="" />
        </router-link>
        <button
          type="button"
          class="btn-close-offcanvas"
          aria-label="Close"
          @click="closeMenu">
          <v-sprite class="icon" icon="close" />
        </button>
      </div>
      <div class="offcanvas-body">
        <v-affiliate-navigation @open-modal="$emit('openModal')" @click="closeMenu" />
        <v-affiliate-qr-code-button @click="closeMenu" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import { defineComponent } from 'vue';

import affiliateRoutesNames from '../router/affiliate-route-names';

export default defineComponent({
  name: 'AffiliateMobileHeader',
  emits: ['openModal'],
  data() {
    return {
      bsOffcanvas: null as (Offcanvas| null),
    };
  },
  computed: {
    affiliateRoutesNames() {
      return affiliateRoutesNames;
    },
  },
  mounted(): void {
    if (this.$refs.offcanvas) {
      this.bsOffcanvas = new Offcanvas((this.$refs.offcanvas as Element));
    }
  },
  methods: {
    toggleMenu(): void {
      if (this.bsOffcanvas) {
        this.bsOffcanvas.toggle();
      }
    },
    closeMenu(): void {
      this.bsOffcanvas?.hide();
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.affiliate-mobile-header {
  background-color: $gray-900;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.mobile-logo {
  width: 64px;
}

.btn-offcanvas {
  background-color: $white;
  border-radius: 50%;
  height: 48px;
  padding: 0;
  width: 48px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.offcanvas {
  background-color: $gray-900;
}

.offcanvas-header {
  margin-bottom: 32px;
  padding: 20px;
}

.offcanvas-body {
  padding: 0 20px 20px;

  .btn {
    margin-top: 20px;
    width: 100%;
  }
}

.btn-close-offcanvas {
  background-color: transparent;
  border: 0;
  padding: 0;

  .icon {
    width: 30px;
  }
}
</style>
