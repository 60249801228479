<template>
  <nav class="app-navigation">
    <ul class="list-unstyled app-navigation-list">
      <li v-if="isPremium">
        <router-link :to="{ name: routeNames.dashboard }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="dashboard" />
          {{ $t('components.app_navigation.home') }}
        </router-link>
      </li>
      <li v-if="isPremium">
        <router-link :to="{ name: routeNames.resumes }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="resume" />
          {{ $t('components.app_navigation.resumes') }}
        </router-link>
      </li>
      <li v-if="isHeadHunter">
        <router-link :to="{ name: routeNames.resumesShop }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="resume" />
          {{ $t('components.app_navigation.resumes') }}
        </router-link>
      </li>

      <!-- Condition mise à jour pour Business Unit -->
      <li v-if="showBusinessUnit">
        <router-link :to="{ name: routeNames.businessUnit }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="business_unit" />
          {{ $t('components.app_navigation.business_unit') }}
        </router-link>
      </li>

      <li>
        <router-link :to="{ name: routeNames.jobs }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="job" />
          {{ $t('components.app_navigation.jobs') }}
        </router-link>
      </li>
      <li v-if="isPremium || isHeadHunter">
        <router-link :to="{ name: routeNames.brand }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="brand" />
          {{ $t('components.app_navigation.brand') }}
        </router-link>
      </li>

      <hr v-if="isPremium || isHeadHunter" />

      <!-- Condition mise à jour pour QR Code -->
      <li v-if="showQrCode">
        <router-link :to="{ name: routeNames.myQrCodes }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="qrcode-small" />
          {{ $t('components.app_navigation.my_qr_codes') }}
        </router-link>
      </li>

      <li v-if="showAddresses">
        <router-link :to="{ name: routeNames.addresses }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="marker" />
          {{ $t('components.app_navigation.addresses') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: routeNames.account }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="" icon="account" />
          {{ $t('components.app_navigation.account') }}
        </router-link>
      </li>
      <li>
        <a href="" class="app-navigation-link" @click.prevent="$emit('openModal')" @keydown.enter.prevent="$emit('openModal')">
          <v-sprite color="" icon="logout" />
          {{ $t('components.app_navigation.logout') }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'AppNavigation',
  emits: ['close-menu', 'openModal'],

  computed: {
    ...mapState('recruiter', ['user']),
    ...mapGetters('recruiter', ['isPremium', 'isHeadHunter']),

    routeNames(): RoutesNames {
      return routesNames;
    },

    showBusinessUnit(): boolean {
      return (this.isPremium || this.isHeadHunter) && this.user?.recruiter?.displayBusinessUnitPage;
    },

    showQrCode(): boolean {
      return this.user?.recruiter?.displayQrCodePage;
    },

    showAddresses(): boolean {
      return this.isPremium && !this.user?.recruiter?.parent;
    },
  },

  methods: {
    ...mapActions('recruiter', ['logout', 'disableGlobal']),
    ...mapActions('register', ['reset']),

    closeMenu(): void {
      this.$emit('close-menu');
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-navigation {
  display: none;

  @include mq($from: desktop) {
    display: block;
    padding-inline: 16px;
  }

  @media print {
    display: none;
  }

  hr {
    height: 2px;
    margin: 0;
    opacity: 1;
  }
}

.app-navigation-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-navigation-link {
  align-items: center;
  border-radius: 8px;
  color: $white;
  display: flex;
  gap: 16px;
  min-height: 40px;
  padding: 10px 8px 8px;
  text-decoration: none;

  svg {
    width: 20px;
  }

  &.router-link-active {
    background-color: $white;
    color: $primary;
  }
}
</style>
