<template>
  <div class="affiliate-navigation">
    <ul class="affiliate-navigation-links">
      <li :class="{ active: $route.name === 'DashboardAffiliateView' }">
        <router-link :to="{ name: affiliateRoutesNames.dashboard }" class="affiliate-navigation-link">
          <v-sprite color="currentColor" icon="affiliate-home" />
          {{ $t('components.app_navigation.home') }}
        </router-link>
      </li>
      <li :class="{ active: $route.name === 'ProfileAffiliateView' }">
        <router-link :to="{ name: affiliateRoutesNames.profile }" class="affiliate-navigation-link">
          <v-sprite color="currentColor" icon="affiliate-profil" />
          {{ $t('components.app_navigation.account') }}
          <span v-if="!recruiter.accountHolderName" class="rib-empty" />
        </router-link>
      </li>
      <li>
        <a href="" class="affiliate-navigation-link" @click.prevent="$emit('openModal')" @keydown.enter.prevent="$emit('openModal')">
          <v-sprite color="currentColor" icon="affiliate-logout" />
          {{ $t('components.app_navigation.logout') }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

import affiliateRoutesNames from '../router/affiliate-route-names';

export default defineComponent({
  name: 'AffiliateNavigation',
  emits: ['openModal'],
  setup() {
    const store = useStore();
    const recruiter = computed(() => store.state.recruiter.user.recruiter);

    return {
      recruiter,
    };
  },
  computed: {
    affiliateRoutesNames() {
      return affiliateRoutesNames;
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.affiliate-navigation-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.affiliate-navigation-link {
  align-items: center;
  border-radius: 8px;
  color: $white;
  display: grid;
  gap: 16px;
  grid-template-columns: 26px minmax(0, 1fr);
  padding: 16px 12px;
  position: relative;
  text-decoration: none;

  &.router-link-active {
    background-color: #ffffff4c;
  }

  svg {
    justify-self: center;
  }
}

.rib-empty {
  background-color: $pink-700;
  border-radius: 50%;
  display: block;
  height: 12px;
  position: absolute;
  right: 12px;
  width: 12px;
}
</style>
