<template>
  <div class="business-unit-card card">
    <div class="card-body">
      <div class="business-unit-card-content">
        <button
          type="button"
          class="btn btn-delete"
          @click.prevent="removeBusinessUnit">
          Supprimer
        </button>

        <div class="business-unit-name">{{ businessUnit.name }}</div>

        <div class="business-unit-jobs">
          ({{ businessUnit.jobs ? businessUnit.jobs.length : 0 }} métiers)
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';

export default defineComponent({
  name: 'BusinessUnitCard',
  props: {
    businessUnit: {
      type: Object as PropType<{ id: number; name: string; jobs: any[]; }>,
      required: true,
    },
  },
  emits: ['business-unit-deleted'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async removeBusinessUnit() {
      try {
        await this.$store.dispatch('recruiter/deleteBusinessUnit', this.businessUnit.id);
        this.$emit('business-unit-deleted', this.businessUnit.id);
        this.toast.success('Pôle d’activité supprimé avec succès');
      } catch {
        this.toast.error('Erreur lors de la suppression du pôle d’activité');
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.business-unit-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  position: relative;
  text-align: center;

  .card-body {
    padding: 25px 0 0;
  }
}

.business-unit-card-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.business-unit-name {
  color: $gray-900;
  font-size: 20px;
}

.business-unit-jobs {
  color: #808080;
  font-size: 14px;
}

.btn-delete {
  background: none;
  border: 0;
  color: $primary;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
