<template>
  <div class="d-flex justify-content-end gap-3 affiliate-btns">
    <button type="button" class="btn btn-rounded btn-primary" @click.prevent="downloadAffiliatePoster('recruiter')">
      {{ $t('views.affiliate_dashboard.download_recruiter_poster') }}
      <v-sprite color="currentColor" icon="download" class="download-icon" />
    </button>

    <button type="button" class="btn btn-rounded btn-outline-primary" @click.prevent="downloadAffiliatePoster('candidate')">
      {{ $t('views.affiliate_dashboard.download_candidate_poster') }}
      <v-sprite color="currentColor" icon="download" class="download-icon" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { mapActions, useStore } from 'vuex';

import useQrCodeGetter from '../composition/qrcode-getter';

export default defineComponent({
  name: 'AffiliateQrCodeButton',
  setup() {
    const { downloadQrCode } = useQrCodeGetter();
    const store = useStore();
    const { recruiter } = store.state.recruiter.user;
    const addressUuid = ref('');

    return {
      recruiter,
      addressUuid,
      downloadQrCode,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions('recruiter', [
      'getProfile',
    ]),
    async fetchData() {
      await this.getProfile();

      const parentAddress = this.recruiter.addresses.find((address) => address.isParent);
      this.addressUuid = parentAddress ? parentAddress.uuid : this.recruiter.addresses[0].uuid;
    },
    async downloadAffiliatePoster(type: string) {
      await this.downloadQrCode(false, this.addressUuid, type);
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.affiliate-btns {
  flex-direction: column;
  margin-top: 20px;

  @include mq($from: wide) {
    flex-flow: row wrap;
    margin-top: 0;
  }

  .btn {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: center;

    svg {
      stroke: currentColor;
    }
  }
}
</style>
