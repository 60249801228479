import Fingerprint from './fingerprint';

const PATH_PATTERN = /\/address\/[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}\/\?type=(candidate|recruiter)$/;
const PATH_PATTERN_RECRUITER = /\/\?uuid=[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/;
const PATH_PATTERN_CANDIDATE = /\/candidate\/\?uuid=[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/;

const fingerprint = Fingerprint.generate();

function shouldRunInit(): boolean {
  return PATH_PATTERN.test(window.location.href)
  || PATH_PATTERN_RECRUITER.test(window.location.href)
  || PATH_PATTERN_CANDIDATE.test(window.location.href);
}

async function postFingerprint(addressUuid: string): Promise<void> {
  const payload = {
    fingerprint,
    address: addressUuid,
  };

  try {
    await fetch('/api/fingerprint/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  } catch (error) {
    throw new Error(`HTTP Error: ${error}`);
  }
}

function init(): void {
  if (!shouldRunInit()) {
    return;
  }

  if (fingerprint) {
    const urlParameters = new URLSearchParams(window.location.search);
    let addressUuid = urlParameters.get('uuid');

    if (!addressUuid) {
      // eslint-disable-next-line prefer-destructuring
      addressUuid = window.location.pathname.split('/')[2];
    }

    if (addressUuid) {
      postFingerprint(addressUuid);
    }
  }
}

init();
