<template>
  <div class="app-footer">
    <div class="app-footer-logo">
      <v-sprite icon="dw" color="#1d1d1f" />
      © {{ currentYear }} salvatore.work - Tous droits réservés
    </div>
    <nav>
      <ul class="app-footer-nav list-unstyled">
        <li><a href="/conditions-generales">Conditions générales</a></li>
        <li><a href="/politique-de-confidentialite" class="text-blue">Politique de confidentialité</a></li>
        <!--
          <li>
          <span class="app-footer-follow-title">Suivez-nous</span>

          <div class="app-footer-follow-links">
            <a href="https://www.facebook.com/profile.php?id=100087326259156" target="_blank" rel="noopener">
              <v-sprite icon="facebook" color="#1d1d1f" />
              <span class="visually-hidden">Facebook</span>
            </a>

            <a href="https://www.linkedin.com/company/decide-work" target="_blank" rel="noopener">
              <v-sprite icon="linkedin" color="#1d1d1f" />
              <span class="visually-hidden">Linked in</span>
            </a>
          </div>
        </li>
        -->
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppFooter',
  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-footer {
  border-top: 1px solid $gray-900;
  color: $body-color;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  gap: 10px 30px;
  justify-content: center;
  margin-top: auto;
  padding: 18px 0 40px;
  position: relative;

  @media print {
    display: none;
  }

  a {
    color: $body-color;
    text-decoration: none;
  }

  .svg-dw {
    height: 20px;
    width: 32px;
  }
}

.app-footer-logo {
  display: inline-flex;
  gap: 20px;

  .svg-dw {
    margin-top: -4px;
    position: relative;
  }
}

.app-footer-nav {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 30px;
  justify-content: center;
  margin-bottom: 0;

  .svg-facebook,
  .svg-linkedin {
    height: 24px;
    width: 24px;
  }
}

.app-footer-follow-title {
  margin-right: 10px;
}

.app-footer-follow-links {
  display: inline-flex;
  gap: 20px;
}
</style>
