<template>
  <div class="brand-recruitment">
    <p class="brand-recruitment-description">
      {{ $t('components.brand_recruitment.description') }}
      <strong class="hint">{{ $t('components.brand_recruitment.hint') }}</strong>
    </p>

    <div class="row">
      <div class="col-sm-6">
        <div v-for="index in visibleSteps" :key="index">
          <div class="form-group position-relative">
            <button
              v-if="selectedRecruitmentSteps[index - 1]"
              type="button"
              class="btn btn-link text-secondary brand-recruitment-delete"
              @click.prevent="deleteStep(index)">
              {{ $t('actions.delete') }}
            </button>
            <label class="form-label" :for="index.toString()">Étape {{ index }}</label>
            <select
              :id="index.toString()" class="form-control form-select select-recruitment"
              :name="index.toString()" @change.prevent="setStep($event, index - 1)">
              <option class="select-option" value="0">Sélectionnez</option>
              <option
                v-for="recruitmentStep in recruitmentSteps"
                :key="recruitmentStep.id"
                class="select-option"
                :value="recruitmentStep.id"
                :selected="recruitmentStep.id === selectedRecruitmentSteps[index - 1]">
                {{ recruitmentStep.name }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="!hasUniqueSteps" class="alert alert-warning">
          {{ $t('components.brand_recruitment.errors.duplicates') }}
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary btn-rounded"
      :disabled="!isUpdated || !hasUniqueSteps"
      @click.prevent="confirmSaveModalOpened = true">
      {{ $t('actions.save') }}
    </button>

    <v-modal id="saveRecruitmentModal" v-model="confirmSaveModalOpened">
      <div class="p-2">
        <h5 class="text-center mb-4">{{ $t('views.brand.save_modal.title') }}</h5>
        <p class="text-center mb-4 steel-text">{{ $t('views.brand.save_modal.description') }}</p>
        <div class="d-flex justify-content-center align-items-center">
          <button
            type="button"
            class="btn btn-outline-primary btn-rounded m-2 cancel-logout-btn"
            @click.prevent="confirmSaveModalOpened = false">
            {{ $t('actions.cancel') }}
          </button>
          <button type="button" class="btn btn-primary btn-rounded m-2" @click.prevent="save()">
            {{ $t('actions.save') }}
          </button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { RecruitmentStep } from '../interfaces/recruitment-step';

export default defineComponent({
  name: 'BrandRecruitment',
  props: {},
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      visibleSteps: 1,
      confirmSaveModalOpened: false,
    };
  },
  computed: {
    ...mapState('brand', [
      'recruitmentSteps',
      'selectedRecruitmentSteps',
      'isUpdated',
    ]),
    ...mapState('recruiter', [
      'currentAddress',
    ]),
    hasUniqueSteps(): boolean {
      return new Set(this.selectedRecruitmentSteps).size === this.selectedRecruitmentSteps.length;
    },
  },
  watch: {
    'currentAddress.recruitmentSteps': {
      handler(newRecruitmentSteps) {
        this.setSelectedRecruitmentSteps([]);

        if (newRecruitmentSteps && Array.isArray(newRecruitmentSteps)) {
          const selectedRecruitmentSteps = newRecruitmentSteps.map((step) => step.id);
          this.setSelectedRecruitmentSteps(selectedRecruitmentSteps);
          this.setVisibleSteps();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted(): Promise<void> {
    await this.getRecruitmentSteps();
    const selectedRecruitmentSteps: Array<number> = [];

    if (this.currentAddress.recruitmentSteps) {
      this.currentAddress.recruitmentSteps.map((recruitmentStep: RecruitmentStep) => selectedRecruitmentSteps.push(recruitmentStep.id));
      await this.setSelectedRecruitmentSteps(selectedRecruitmentSteps);
      this.setVisibleSteps();
    }
  },
  methods: {
    ...mapActions('brand', [
      'getRecruitmentSteps',
      'setSelectedRecruitmentSteps',
      'saveRecruitmentSteps',
      'setIsUpdated',
    ]),
    ...mapActions('recruiter', [
      'setCurrentAddress',
    ]),
    setVisibleSteps(): void {
      let visibleSteps = this.selectedRecruitmentSteps.length + 1;

      if (visibleSteps > 6) {
        visibleSteps = 6;
      }

      this.visibleSteps = visibleSteps;
    },
    setStep(event: any, step: number): void {
      this.setIsUpdated(true);
      const selectedRecruitmentSteps = [...this.selectedRecruitmentSteps];
      selectedRecruitmentSteps[step] = Number.parseInt(event.target.value, 10);
      this.setSelectedRecruitmentSteps(selectedRecruitmentSteps);
      this.setVisibleSteps();
    },
    deleteStep(step: number): void {
      this.setIsUpdated(true);
      const index = step - 1;

      // Clone the array
      let selectedRecruitmentSteps = [...this.selectedRecruitmentSteps];

      // Delete the step
      if (index in selectedRecruitmentSteps) {
        delete selectedRecruitmentSteps[index];
      }

      // Reindex the array
      selectedRecruitmentSteps = selectedRecruitmentSteps.filter(Boolean);

      // Save everything
      this.setSelectedRecruitmentSteps(selectedRecruitmentSteps);
      this.setVisibleSteps();
    },
    async save(): Promise<void> {
      try {
        let selectedRecruitmentSteps = [...this.selectedRecruitmentSteps];
        selectedRecruitmentSteps = selectedRecruitmentSteps.filter((step) => step !== 0);
        await this.setSelectedRecruitmentSteps(selectedRecruitmentSteps);
        await this.saveRecruitmentSteps();
        this.setCurrentAddress(this.currentAddress);
        this.setVisibleSteps();
        this.confirmSaveModalOpened = false;
        this.toast.success((this.$t('components.brand_recruitment.save.success') as string));
      } catch {
        this.toast.error((this.$t('components.brand_recruitment.save.error') as string));
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-recruitment-description {
  font-size: 14px;

  .hint {
    color: $secondary;
  }
}

.form-label {
  color: $primary;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
}

.form-control {
  height: 48px;
}

.brand-recruitment-delete {
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
}

.select-recruitment {
  border: hidden;
  border-radius: 6pt;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .075);
}

.select-recruitment > option {
  background-color: #fff;
  color: #1a225f !important;

  &:hover {
    background-color: #9e99d81a;
  }
}

</style>
