<template>
  <div class="checkbox-indicator" :class="{ checked }">
    <v-sprite v-if="checked" icon="check" :color="color" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckboxIndicator',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: '#001659',
    },
  },
});
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '../../scss/config/config';

.checkbox-indicator {
  align-items: center;
  border: 1px solid $black;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 6px;
  width: 24px;
}
</style>
