<template>
  <div class="applications-list">
    <div
      v-for="(applicationsByDate, date) in applicationsGroupedByDate"
      :key="date"
      class="date-list mb-3"
      :class="{ 'not-empty': applicationsGroupedByDate.length }">
      <span class="date-badge rounded text-xs">{{ dateFormatted(date as string) }}</span>
      <v-applications-list-item
        v-for="application in applicationsByDate" :key="application.uuid"
        :application="application"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { format, parse } from 'date-fns';
import { fr } from 'date-fns/locale/fr';
import { defineComponent, PropType } from 'vue';

import Application from '../interfaces/application';

interface ApplicationGroupedByDate {
  [date: string]: Application[];
}

export default defineComponent({
  name: 'ApplicationsList',
  props: {
    applications: {
      type: Object as PropType<Application[]>,
      required: true,
    },
  },
  computed: {
    applicationsGroupedByDate(): ApplicationGroupedByDate {
      return this.applications.reduce((previousApplications: ApplicationGroupedByDate, currentApplication: Application) => {
        const applications: ApplicationGroupedByDate = previousApplications;
        applications[currentApplication.completedAt] = [...applications[currentApplication.completedAt] || [], currentApplication];
        return applications;
      }, {});
    },
  },
  methods: {
    // eslint-disable-next-line class-methods-use-this
    dateFormatted(applicationDate: string): string {
      const date = parse(applicationDate, 'yyyy-MM-dd', new Date());
      return format(date, 'dd MMM yyyy', { locale: fr });
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.applications-list.not-empty {
  min-height: 272px;
}

.date-badge {
  background-color: $purple;
  padding: 5px 10px;
  text-transform: capitalize;
}

</style>
