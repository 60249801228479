<template>
  <div class="brand-advantages">
    <p class="brand-advantages-description steel-text">{{ $t('components.brand_advantages.description') }}</p>

    <div v-for="category in advantages" :key="category.id" class="brand-advantages-category">
      <h2 class="brand-advantages-category-title">{{ category.name }}</h2>

      <div class="brand-advantages-category-list">
        <button
          v-for="advantage in category.advantages"
          :key="advantage.id"
          type="button"
          class="brand-advantages-category-list-item"
          :class="{ 'brand-advantages-category-list-item-selected': selectedAdvantages.includes(advantage.id) }"
          @click="toggleSelectedAdvantage(advantage)">
          <v-sprite
            v-if="selectedAdvantages.includes(advantage.id)"
            color="var(--bs-primary)" icon="validate" class="brand-advantages-category-list-item-validate" />
          <img :src="advantage.icon" :alt="advantage.name" class="img-fluid">
          {{ advantage.name }}
        </button>
      </div>
    </div>

    <div class="brand-advantages-category">
      <h2 class="brand-advantages-category-title">{{ $t('components.brand_advantages.other') }}</h2>

      <div class="brand-advantages-category-list">
        <div
          v-for="advantage in customAdvantages"
          :key="advantage.id"
          class="brand-advantages-category-list-item steel-text">
          {{ advantage.name }}
          <button
            type="button"
            class="brand-advantages-category-list-item-delete"
            @click.prevent="deleteAdvantage(advantage)">
            <v-sprite icon="delete" />
          </button>
        </div>

        <button
          type="button"
          class="brand-advantages-category-list-item brand-advantages-category-list-item-add"
          @click.prevent="newAdvantageModalOpened = true">
          <v-sprite icon="plus-pink" />
          {{ $t('views.brand.add') }}
        </button>

        <v-modal
          id="newAdvantageModal"
          v-model="newAdvantageModalOpened"
          class="advantage-modal">
          <h4 class="text-center">{{ $t('modals.new_advantage.title') }}</h4>

          <form class="mx-5 mb-4" action="" @submit.prevent="submitAdvantage()">
            <div class="form-group form-group-required">
              <label for="company">{{ $t('components.brand_advantages.name') }}</label>
              <input
                id="name"
                v-model="newAdvantageName"
                type="text"
                class="form-control"
                name="name"
                :placeholder="$t('modals.new_advantage.placeholder')"
                :class="{ 'is-invalid': newAdvantageName && v.newAdvantageName.$invalid }">
              <div class="form-text text-end mt-2">{{ $t("modals.new_advantage.max_length") }}</div>
            </div>

            <div class="form-group d-grid">
              <button type="submit" class="btn btn-primary btn-save advantage-modal-save " :disabled="v.newAdvantageName.$invalid">
                {{ $t('actions.save') }}
              </button>
            </div>
          </form>
        </v-modal>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary btn-rounded"
      :disabled="!isUpdated"
      @click.prevent="confirmSaveModalOpened = true">
      {{ $t('actions.save') }}
    </button>

    <v-modal id="saveAdvantagesModal" v-model="confirmSaveModalOpened">
      <div class="p-2">
        <h5 class="text-center mb-4">{{ $t('views.brand.save_modal.title') }}</h5>
        <p class="text-center mb-4">{{ $t('views.brand.save_modal.description') }}</p>
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-light m-2 cancel-logout-btn" @click.prevent="confirmSaveModalOpened = false">
            {{ $t('actions.cancel') }}
          </button>
          <button type="button" class="btn btn-primary m-2" @click.prevent="save()">
            {{ $t('actions.save') }}
          </button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapGetters, mapState } from 'vuex';

import { Advantage } from '../interfaces/advantage';

export default defineComponent({
  name: 'BrandAdvantages',
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      confirmSaveModalOpened: false,
      newAdvantageModalOpened: false,
      newAdvantageName: '',
    };
  },
  validations() {
    return { newAdvantageName: { required } };
  },
  computed: {
    ...mapState('brand', [
      'currentTab',
      'advantages',
      'selectedAdvantages',
      'isUpdated',
    ]),
    ...mapState('recruiter', [
      'currentAddress',
    ]),
    ...mapGetters('recruiter', [
      'customAdvantages',
    ]),
  },
  watch: {
    currentAddress: {
      handler(newAddress, oldAddress) {
        if (newAddress && newAddress.uuid !== oldAddress?.uuid) {
          this.setSelectedAdvantages([]);

          if (newAddress.advantages && Array.isArray(newAddress.advantages)) {
            const selectedAdvantages = newAddress.advantages.map((advantage) => advantage.id);
            this.setSelectedAdvantages(selectedAdvantages);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted(): Promise<void> {
    await this.getAdvantages();
    const selectedAdvantages: Array<number> = [];

    if (this.currentAddress.advantages) {
      this.currentAddress.advantages.map((advantage: Advantage) => selectedAdvantages.push(advantage.id));
      this.setSelectedAdvantages(selectedAdvantages);
    }
  },
  methods: {
    ...mapActions('brand', [
      'setCurrentTab',
      'setIsUpdated',
      'getAdvantages',
      'toggleSelectedAdvantage',
      'setSelectedAdvantages',
      'saveAdvantages',
    ]),
    ...mapActions('recruiter', [
      'setCurrentAddress',
    ]),
    async save(): Promise<void> {
      try {
        await this.saveAdvantages();
        this.setCurrentAddress(this.currentAddress);
        this.confirmSaveModalOpened = false;
        this.toast.success((this.$t('components.brand_advantages.save.success') as string));
      } catch {
        this.toast.error((this.$t('components.brand_advantages.save.error') as string));
      }
    },
    async submitAdvantage(): Promise<void> {
      try {
        await window.ky.post('/api/advantages/', { json: { name: this.newAdvantageName, address: this.currentAddress.id } }).json<void>();
        this.newAdvantageModalOpened = false;
        this.newAdvantageName = '';
        this.setCurrentAddress(this.currentAddress);
        this.toast.success((this.$t('components.brand_advantages.save.success') as string));
      } catch {
        this.toast.error((this.$t('components.brand_advantages.save.error') as string));
      }
    },

    async deleteAdvantage(advantage: Advantage): Promise<void> {
      try {
        await window.ky.delete(`/api/advantages/${advantage.id}`).json<void>();
        this.setCurrentAddress(this.currentAddress);
        this.toast.success((this.$t('components.brand_advantages.delete.success') as string));
      } catch {
        this.toast.error((this.$t('components.brand_advantages.delete.error') as string));
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-advantages-description {
  font-size: 14px;
}

.brand-advantages-category {
  margin-bottom: $spacer;
}

.brand-advantages-category-title {
  color: $primary;
  font-size: 16px;
  font-weight: 700;
}

.brand-advantages-category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.brand-advantages-category-list-item {
  align-items: center;
  background-color: $white;
  border: 1px solid $white;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .07);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 92px;
  justify-content: center;
  line-height: $headings-line-height;
  position: relative;
  text-align: center;
  width: 75px;

  img,
  svg {
    margin-bottom: 5px;
  }
}

.brand-advantages-category-list-item-add {
  background-color: rgba(255, 14, 141, .15);
  color: $steel;
}

.brand-advantages-category-list-item-selected {
  border-color: $primary;
}

.brand-advantages-category-list-item-validate {
  position: absolute;
  right: -1px;
  top: -1px;
}

.brand-advantages-category-list-item-delete {
  background: transparent;
  border: 0;
  border-radius: 100%;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 5px;
}

.advantage-modal-input {
  border: $blue-200;
  border-radius: 6pt;
}

.advantage-modal-save {
  border: hidden;
}

</style>
